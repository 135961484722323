<header class="ba-content-header">
  <h1 id="ueberschrift">
    Ups! 404 Fehler, leider k&ouml;nnen wir die von Ihnen angeforderte Seite nicht finden
  </h1>
</header>
<article class="ba-content-article ba-bg-semibright">
  <section class="ba-editor-content">
    <h2 class="text-center" id="ueberschrift">
      M&ouml;gliche Gr&uuml;nde hierf&uuml;r k&ouml;nnten sein:
    </h2>
    <div id="content">
      <ul>
        <li>Der Link ist veraltet und diese Seite ist nicht mehr zu erreichen</li>
        <li>
          M&ouml;glicherweise ist die betroffene Seite auch archiviert, verschoben oder umbenannt
          worden
        </li>
      </ul>
      <p>Vielen Dank f&uuml;r Ihr Verst&auml;ndnis und viel Vergn&uuml;gen auf unserer Webseite.</p>
    </div>
    <div class="text-right">
      <a
        routerLink="/suche"
        title="Zur Studiensuche"
        class="ba-btn ba-btn-primary ba-btn-icon ba-icon-chevron-right-after"
        id="showstudiensuche"
        role="button"
      >
        Zur Studiensuche
      </a>
    </div>
  </section>
</article>
