import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ba-studisu-seitenichtgefundentxt',
  templateUrl: './seitenichtgefundentxt.component.html',
  standalone: true,
  imports: [RouterLink]
})
export class SeiteNichtGefundenTxtComponent {}
